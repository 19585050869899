import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import SEO from '../components/common/seo';
import { getCategoryColor } from '../components/context/AppContext';
import { Title } from '../components/common/Title';
import { getFeaturedImageSrc, getImageSrc } from '../utils/data';
import { InternalExternalLink } from '../components/common/InternalExternalLink';
import { HubAudioPlayer } from '../components/common/HubAudioPlayer';

import styles from './About.module.scss';

export const pageQuery = graphql`
  query AboutPageQuery {
    about: wpPage(slug: { eq: "about" }) {
      title
      content
      funders {
        funders {
          funderUrl
          funderLogo {
            localFile {
              publicURL
              childImageSharp {
                fluid(maxWidth: 640) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          funderName
        }
      }
      featuredImage {
        node {
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1280) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
      audioAlternative {
        longDescriptionAudioAlternative {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;

const AboutPage = ({ data }) => {
  const { about } = data;
  const { content } = about;
  const audioFile = about.audioAlternative.longDescriptionAudioAlternative?.localFile?.publicURL;
  return (
    <>
      <SEO title="About" />
      <Helmet
        bodyAttributes={{
          class: getCategoryColor(),
        }}
      />

      <div className={styles.row}>
        <div className={styles.stickPhoto}>
          <img src={getFeaturedImageSrc(about.featuredImage)} alt="" />
        </div>

        <div className={styles.rowContent}>
          <Title size="large" tagName="h1">
            {about.title}
          </Title>

          <div
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />

          {audioFile && (
            <div className={styles.rowContentAudio}>
              <HubAudioPlayer src={audioFile} label="Listen" />
            </div>
          )}

          <Title className={styles.fundersTitle} size="large" tagName="h2">
            Our Partners
          </Title>

          <ul className={styles.funders}>
            {about.funders.funders.map(item => {
              return (
                <li key={item.funderName}>
                  <InternalExternalLink to={item.funderUrl}>
                    <img src={getImageSrc(item.funderLogo)} alt="" />
                  </InternalExternalLink>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};
export default AboutPage;
